<template>
  <div class="general-container">
    <the-navbar />
    <project-all />
    <!-- TODO: Traer titles desde router/index.js -->
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import ProjectAll from '@/components/ProjectAll.vue'

export default {
  name: 'Proyectos',
  components: {
    TheNavbar,
    ProjectAll
  }
}
</script>
