<template>
  <div
    class="column"
    :class="viewType"
  >
    <!-- CARD -->
    <div v-if="isFormatGrid">
      <localized-router-link
        v-slot="{ href, navigate }"
        :to="projectURL"
        custom
      >
        <a
          :class="{ card: true, participant: isParticipant }"
          :href="href"
          @click="navigate"
        >
          <div class="card__header">
            <div
              class="card__header-logo"
              :style="{ 'background': iconData.backgroundColor }"
            >
              <img :src="require('../assets/dashboard/' + iconData.image)">
            </div>
            <div
              class="card__header-status"
              :class="projectStatusClass"
            >
              {{ projectStatusText }}
            </div>
            <div
              v-if="!isParticipant"
              class="card__header-actions"
            >
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                @click.native.stop
              >
                <button
                  slot="trigger"
                  class="button is-primary"
                />
                <b-dropdown-item aria-role="listitem">
                  {{ $t('global.edit') }}
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem">
                  {{ $t('global.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card__info">
            <div class="card__info-title">
              {{ project.name | capitalize }}
            </div>
            <div class="card__info-date">
              <span>{{ $t('global.ends_on') }}</span> {{ convertFinishDate }}
            </div>
            <div class="card__info-client">
              <span>{{ $t('global.organizer') }}</span> {{ project.client | capitalize }}
            </div>
          </div>
          <div
            v-if="!isParticipant"
            class="card__progress"
            :class="showProgressBar"
          >
            <div class="card__progress-bar">
              <span
                class="card__progress-bar-fill"
                :style="`width: ${progressBar}%;`"
              />
            </div>
            <div class="card__progress-data">
              <div>{{ $t('global.participants') }}</div>
              <div>
                {{ computedParticipantCount }} / {{ project.participantsLimit }}
              </div>
            </div>
          </div>
        </a>
      </localized-router-link>
    </div>

    <!-- ROW -->
    <div v-if="isFormatList">
      <div
        class="card row"
        :class="{ participant: isParticipant }"
        @click="viewProject(project.identifier)"
      >
        <div
          class="card__logo"
          :style="{ 'background': iconData.backgroundColor }"
        >
          <img :src="require('../assets/dashboard/' + iconData.image)">
        </div>
        <div class="card__title">
          <span>{{ project.name | capitalize }}</span>
          <div
            class="card__bullet"
            :class="projectStatusClass"
          />
        </div>
        <div class="card__center">
          <div class="card__date">
            <span>Finaliza el</span> {{ convertFinishDate }}
          </div>
          <div class="card__client">
            <span>Organizador</span> {{ project.client | capitalize }}
          </div>
        </div>
        <div
          v-if="!isParticipant"
          class="card__progress-bar"
          :class="showProgressBar"
        >
          <span
            class="card__progress-bar-fill"
            :style="`width: ${progressBar}%;`"
          />
        </div>
        <div
          v-if="!isParticipant"
          class="card__progress-data"
          :class="showProgressBar"
        >
          {{ project.participantCount }} / {{ project.participantsLimit }}
        </div>
        <div
          v-if="!isParticipant"
          class="card__actions"
        >
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            @click.native.stop
          >
            <button
              slot="trigger"
              class="button is-primary"
            />
            <b-dropdown-item aria-role="listitem">
              Editar
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedConstants from '@/shared/constants.json'
import { formatDate } from '@/dates'
import vue from '../main'

export default {
  name: 'ProjectItem',
  props: {
    project: {
      type: Object,
      default: null
    },
    format: {
      type: String,
      default: 'grid'
    }
  },
  computed: {
    iconData() {
      switch (this.project.projectType) {
        case sharedConstants.PROJECT_TYPE.COMMUNITY:
          return {
            image: 'icon-chart.svg',
            backgroundColor: '#eaf7fc'
          }
        case sharedConstants.PROJECT_TYPE.OPEN_QUESTIONNAIRE:
        default:
          return {
            image: 'icon-lightbulb.svg',
            backgroundColor: '#fff9e7'
          }
      }
    },
    viewType() {
      return this.format === 'grid' ? 'is-one-quarter' : 'is-full'
    },

    isFormatList() {
      return this.format === 'list'
    },

    isFormatGrid() {
      return this.format === 'grid'
    },

    projectStatusClass() {
      if (!this.isParticipant) {
        return this.project.status
      }

      if (this.project.projectEnded) {
        return sharedConstants.PROJECT_STATUS.FINALIZED
      }

      return this.project.participants[0].status
    },

    projectStatusText() {
      if (!this.isParticipant) {
        return this.managerProjectStatus
      }
      return this.participantProjectStatus
    },

    participantProjectStatus() {
      if (this.project.projectEnded) {
        return vue.$t('project_participation_status.project_ended')
      }

      if (this.project.participants[0].status === sharedConstants.PARTICIPANT_STATUS.IN_PROGRESS) {
        return vue.$t('project_participation_status.in_progress')
      }

      if (this.project.participants[0].status === sharedConstants.PARTICIPANT_STATUS.COMPLETE) {
        return vue.$t('project_participation_status.complete')
      }

      return vue.$t('project_participation_status.not_started')
    },

    projectURL() {
      if (this.isParticipant) {
        return {
          name: 'project-detail',
          params: {
            projectIdentifier: this.project.identifier
          }
        }
      }

      if (this.project.projectType === sharedConstants.PROJECT_TYPE.COMMUNITY) {
        return {
          name: 'project-dashboard-summary',
          params: { projectIdentifier: this.project.identifier }
        }
      }

      if (this.project.activities.length > 0) {
        const params = {
          projectIdentifier: this.project.identifier,
          activityIdentifier: this.project.activities[0].identifier
        }

        if (this.project.status === sharedConstants.PROJECT_STATUS.NOT_PUBLISHED) {
          return {
            name: 'edit-activity',
            params
          }
        }

        return {
          name: 'activity-dashboard',
          params
        }
      }

      return '/'
    },

    managerProjectStatus() {
      if (this.project.status === sharedConstants.PROJECT_STATUS.ACTIVE) {
        return vue.$t('client_project_status.active')
      }

      if (this.project.status === sharedConstants.PROJECT_STATUS.CANCELED) {
        return vue.$t('client_project_status.cancelled')
      }

      if (this.project.status === sharedConstants.PROJECT_STATUS.FINALIZED) {
        return vue.$t('client_project_status.project_ended')
      }

      return vue.$t('client_project_status.not_published')
    },

    convertFinishDate() {
      return formatDate(this.project.finishDate)
    },

    computedParticipantCount() {
      if (this.project.projectType === sharedConstants.PROJECT_TYPE.OPEN_QUESTIONNAIRE) {
        return this.project.completedParticipantCount
      }

      return this.project.participantCount
    },

    progressBar() {
      return (
        (this.computedParticipantCount * 100) / this.project.participantsLimit
      )
    },

    showProgressBar() {
      return this.project.status === 'notpublished' ? 'hidden' : ''
    }
  },
  methods: {
    viewProject(projectId) {
      if (this.isParticipant) {
        this.$router.push({
          name: 'participation-terms-and-conditions',
          params: { id: projectId }
        })
      } else if (
        this.project.status === sharedConstants.PROJECT_STATUS.ACTIVE
          || this.project.status === sharedConstants.PROJECT_STATUS.FINALIZED
      ) {
        this.$router.push({
          name: 'resumen',
          params: { id: projectId }
        })
      } else if (
        this.project.projectType
            === sharedConstants.PROJECT_TYPE.OPEN_QUESTIONNAIRE
      ) {
        this.$router.push({
          name: 'actividad',
          params: { id: projectId }
        })
      } else {
        this.$router.push({
          name: 'project-detail',
          params: { id: projectId }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/projectitem_scoped.scss';
</style>

<style lang="scss">
  @import '../scss/projectitem.scss';
</style>
