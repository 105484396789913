<template>
  <div class="wide-column extra-top-padding page-bottom-padding">
    <b-loading
      v-if="loading"
      :is-full-page="true"
      :can-cancel="false"
      :active="true"
    />
    <template v-else-if="sortedProjects.length > 0">
      <div
        class="header-bar"
      >
        <div class="section-title">
          <h1>{{ $t('project_listing.main_title') }}</h1>
        </div>
        <!--
        <div class="format-list">
          <div
            class="list"
            :class="{ active: isFormatList}"
            @click.prevent="changeViewType('list')"
          />
          <div
            class="grid"
            :class="{ active: isFormatGrid}"
            @click.prevent="changeViewType('grid')"
          />
        </div>
        -->
      </div>
      <div class="grid-view">
        <div class="columns is-multiline is-variable is-4 card-grid">
          <project-item
            v-for="(project, index) in sortedProjects"
            :key="index"
            :project="project"
            :format="projectViewFormat"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectItem from '@/components/ProjectItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectAll',
  components: {
    ProjectItem
  },
  props: {
    pageTitle: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isGridView: true,
      projectViewFormat: 'grid',

      loading: false
    }
  },

  computed: {
    ...mapGetters('Projects', ['sortedProjects']),

    isFormatList() {
      return this.projectViewFormat === 'list'
    },
    isFormatGrid() {
      return this.projectViewFormat === 'grid'
    }
  },

  async created() {
    this.loading = true

    // Dispatch action 'getProject' from the Vuex Store
    try {
      await this.$store.dispatch('Projects/getProjectsData')
      this.loading = false
    } catch {
      this.loading = false
      this.$store.dispatch(
        'alerts/triggerErrorAlert',
        {
          indefinite: true,
          message: 'Ha ocurrido un error al obtener el listado de proyectos'
        }
      )
    }
  },

  mounted() {
    this.scrollToTop()
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    changeViewType(type) {
      this.projectViewFormat = type
    }
  }
}
</script>

<style lang="scss" scoped>
.wide-column {
  .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 14px;

    @include respond(mobile) {
      margin-bottom: 20px;
    }

    .section-title {
      h1 {
        position: relative;
        top: -12px;
        font-size: 30px;
        font-weight: 600;
        user-select: none;

        @include respond(mobile) {
          top: -8px;
          text-align: left;
          font-size: 24px;
        }
      }
    }

    .format-list {
      display: flex;
      align-items: flex-start;

      .grid,
      .list {
        width: 27px;
        height: 33px;
        cursor: pointer;
      }

      .list {
        margin-left: 38px;
        background: url("../assets/dashboard/icon-list.svg") center top no-repeat;
        background-size: contain;
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out;

        &:hover {
          &:not(.active) {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
          }
        }

        &.active {
          background: url("../assets/dashboard/icon-list-active.svg") center top no-repeat;
          background-size: contain;
          opacity: 1;
        }

        @include respond(mobile) {
          margin-left: 20px;
          width: 22px;
          height: 25px;
        }
      }

      .grid {
        margin-left: 38px;
        background: url("../assets/dashboard/icon-grid.svg") center top
          no-repeat;
        background-size: contain;
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out;

        &:hover {
          &:not(.active) {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
          }
        }

        &.active {
          background: url("../assets/dashboard/icon-grid-active.svg") center
            top no-repeat;
          background-size: contain;
          opacity: 1;
        }

        @include respond(mobile) {
          margin-left: 20px;
          width: 22px;
          height: 25px;
        }
      }
    }
  }
}

// for animate

.slide-fade-enter-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  transition-delay: 0.5s;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  transition-delay: 0.2s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10rem);
  transform-origin: top center;
  opacity: 0;
}
</style>
